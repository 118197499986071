<template>
  <div
    :style="{ height: this.height + 50, background: 'var(--widgetBg)' }"
    class="total-container"
  >
    <div class="button-container">
      <div class="flex px-2 zoom-container">
        <div
          :style="{ fontSize: 9 + 'px', marginRight: '10px' }"
          class="mt-1 settingPopColor mb-1"
        >
          <!-- <span class="zoom-label">Zoom</span> -->
        </div>
        <!-- <div class="ml-0.5 tool_section">
            <input
              type="checkbox"
              :id="'zoom'"
              class="tool_toggle_input"
              v-model="isZoomEnable"
            />
            <label class="tool_toggle_label _zoom ml-1 mb-1" :for="'zoom'"
              >Zoom :</label
            >
          </div> -->
      </div>

      <button v-if="!noBhaFound" class="button" @click="onclickofreset">
        Reset
      </button>
    </div>
    <div v-if="!noBhaFound">
      <p class="zoom-label">HoleDepth : {{ holedepth.toFixed(3) }}</p>
      <p class="zoom-label">BitDepth : {{ bitdepth.toFixed(3) }}</p>
    </div>
    <!-- <div style="height:600px ; border: 1px solid black;"> -->
    <canvas
      v-if="!noBhaFound"
      class="canvas"
      ref="drillingCanvas"
      :width="this.width"
      :height="this.height"
      @mousemove="handleMouseMove"
      @mouseleave="handleCanvasMouseLeave"
    ></canvas>
    <div
      v-if="noBhaFound"
      class="flex flex-row justify-center items-center"
      :style="{ width: width + 'px', height: height + 'px' }"
    >
      <h1 :style="{ color: 'var(--activeTextColor)' }">BHA NOT FOUND</h1>
    </div>
    <!-- </div> -->
    <div
      id="tooltip"
      class="absolute tooltip"
      v-if="tooltipContent && !isCasingTooltip"
      :style="{ top: tooltipTop + 'px', left: tooltipLeft + 'px' }"
    >
      Name :{{
        isCasingTooltip
          ? tooltipContent.sectionType
          : tooltipContent.bhaData.componenttype
      }}
      <br />Length : {{ tooltipContent.componentLength.toFixed(3) }} ft
      <br />
      OD : {{ tooltipContent.odia }} inch <br />
      ID : {{ tooltipContent.idia }} inch <br />
      ComponentDepth : {{ tooltipContent.componentDepth.toFixed(3) }} ft
    </div>
    <div
      id="tooltip"
      class="absolute tooltip"
      v-if="tooltipContent && isCasingTooltip"
      :style="{ top: tooltipTop + 'px', left: tooltipLeft + 'px' }"
    >
      <div>
        <p class="details">Casing Details</p>
        SectionType :
        {{ tooltipContent.sectionType }}
        <br />
        <!-- time : {{ tooltipContent.time }} <br /> -->
        OD : {{ tooltipContent.odia }} inch <br />
        ID : {{ tooltipContent.idia }} inch <br />
        MD : {{ tooltipContent.md }} ft <br />
        TVD : {{ tooltipContent.tvd }} ft
      </div>

      <!-- wellBoreName :{{ tooltipContent.wellBoreName }}  -->
    </div>
    <p v-if="!noBhaFound" :style="{ color: 'var(--activeTextColor)' ,marginBottom : '5px',marginTop : '5px'}">
      BHA Stick Diagram (vertical well)
    </p>
  </div>
</template>

<script>
import drillBitImg from "../../../assets/img/goldenbit1.png";
import drillBitImg2 from "../../../assets/img/goldenbit2.png";
import drillBitImg3 from "../../../assets/img/goldenbit3.png";
import stabilizerImg from "../../../assets/img/stabilizer2.png";
import apiService from "../../../api/services";

// import axios from "axios";
import Axios from "axios";
import constant from "../../../api/constant";
import * as d3 from "d3";
import { color } from "d3";
export default {
  name: "StickDiagram",
  data() {
    return {
      lastMouseY: null,
      lastMouseX: null,
      noBhaFound: false,

      zoom: null,
      initialTransform: null,
      isZoomEnable: true,
      timeInterval: null,
      tooltipContent: null,
      components: [],
      pipeWidth: 30,
      pipeHeight: 50,
      casingPipeWidth: 40,
      casingPipeHeight: 60,
      stabilizerWidth: 40,
      stabilizerHeight: 20,
      bitWidth: 50,
      bitHeight: 50,
      drillBitImg: null,
      stabilizerImg: null,
      ReamerImg: null,
      tableData: [],
      commonwidth: null,
      coomonStartx: null,
      width: 400,
      names: [
        "bit",
        "pipe",
        "Mud Motor",
        "MWD",
        "LWD",
        "stabilizer",
        "Reamer",
        "Drill Collar",
        "NMDC",
        "Mule Shoe",
        "Float Sub",
        "Cross Over",
        "Jar",
        "HWDP",
        "SUB",
        "Others",
      ],
      context: null,
      hoveredRowData: null, // Store the hovered row data
      tooltipTop: 0, // Add an offset if needed
      tooltipLeft: 0,
      drillBitImages: [drillBitImg, drillBitImg2, drillBitImg3],
      // Paths to the images
      drillBitImgs: [], // To hold Image objects
      currentImageIndex: 0,
      animationFrameId: null,
      backgroundImageSrc: null, // Path to the background image
      backgroundImage: null,
      leftlinestartx: null,
      showTooltip: null,
      hoverobject: {
        startX: 0,
        startY: 0,
        endY: 0,
        name: "",
      },
      bitHeight: 0,
      isAnimate: false,
      animatingy: 0,
      animatedInterval: null,
      isTripin: false,
      rotateBit: false,
      bitdepth: this.dbtm,
      holedepth: this.dmea,
      yScale: null,
      canvas: null,
      zoomTransform: d3.zoomIdentity,
      miny: 0,
      maxy: this.holedepth,
      zoomedScale: null,
      wellGeometryList: [],
      mousePositionsForCasing: [],
      isCasingTooltip: false,
      planId: "",
      originalData: [],
      intervalfortripout: null,
      isZoomSatisfied: false,
    };
  },
  computed: {
    axisColor() {
      return getComputedStyle(this.canvas).getPropertyValue("--textColor");
    },
  },
  async mounted() {
    console.log("coming to rerender ~~~~~~~");
    // console.log("coming to mounted afte refresh");

    await this.loadImages();
    // this.loadBackgroundImage()
    // console.log(this.drillBitImages, "drillbit images");

    this.stabilizerImg = new Image();
    this.stabilizerImg.src = stabilizerImg;
    // const canvas = this.$refs.drillingCanvas;
    // this.canvas = canvas;
    // console.log(this.canvas, canvas, "from mounted");
    // this.context = canvas.getContext("2d");

    const canvas = this.$refs.drillingCanvas;
    this.canvas = canvas;

    if (canvas) {
      this.context = canvas.getContext("2d");
      if (this.context) {
        this.initializeZoom();
        this.getdata();
        // this.holedepth = 10000
        // this.bitdepth = 8000

        // this.drawComponents();
        this.onclickoftripout();
      } else {
        console.error("Failed to obtain canvas context");
      }
    }
    // setInterval(()=>{
    //   this.bitdepth += 1
    //   this.holedepth += 1
    //   this.tableData = JSON.parse(JSON.stringify(this.originalData));
    //   this.onclickoftripout();
    // },100)

    // this.stabilizerImg.onload = this.drawComponents;

    // this.initializeZoom();
    // await this.getdata();
    // await this.drawComponents()
    // this.onclickoftripout();
  },
  methods: {
    async getdata() {
      const BearerToken = localStorage.getItem("apollodart");
      // console.log(BearerToken, "bearertoken");
      // console.log("coming to getdata");
      const url3 = constant.DATASERVICES_URL + "bha/planids";
      const cutsomer = this.$store.state.data.customer;
      // console.log(customer,'customer from stcik diagram')
      if(this.$store.state.data.customer != undefined){

        const payload3 = {
          customerName: cutsomer,
          wellId: this.selectedWell,
          wellBoreName: this.selectedWellbore,
        };
  
        try {
          const response = await Axios.post(url3, payload3);
          console.log(response.data, "response from the plainids");
          if (response.status == 200) {
            this.noBhaFound = false;
            this.planId = response.data[response.data.length - 1].planId;
            const url = constant.DATASERVICES_URL + "bha/search";
            const payload = {
              planId: this.planId,
            };
  
            try {
              const response = await Axios.post(url, payload);
              this.tableData = response.data;
              this.originalData = JSON.parse(JSON.stringify(response.data));
              // console.log(this.tableData, "table daata evena");
            } catch (error) {
              // Handle errors here
              // console.log(error);
            }
          } else {
            this.noBhaFound = true;
          }
        } catch (error) {
          this.noBhaFound = true;
          // Handle errors here
          // console.log(error);
        }
  
        const url1 = constant.DATASERVICES_URL + "wellgeometry/search";
        const payload1 = {
          wellId: this.selectedWell,
          wellBoreName: this.selectedWellbore,
        };
  
        try {
          const response = await Axios.post(url1, payload1);
          //   console.log("well geometry", response.data);
          this.wellGeometryList = response.data;
        } catch (error) {
          // Handle errors here
          console.log(error);
        }
      }

      // const response = await this.getTagValue("disp", "dmea");
      // const responseOfDbtm = await this.getTagValue("disp", "dbtm");
      // console.log(responseOfDbtm, "two responses ");
      // if (response) {
      //   this.holedepth = response.data[0].dmea;
      // }
      // if (responseOfDbtm) {
      //   this.bitdepth = responseOfDbtm.data[0].dbtm;
      // }
    },
    async getTagValue(displayDtls, title) {
      let value;
      if (this.max) {
        let result = "";
        let mnemonic_type = "SOLUTION";
        if (this.selectedLog == "time")
          result = await apiService.WellServices.getSingleValueTimeLog({
            well_id: this.selectedWell,
            wellbore_name: this.selectedWellbore,
            field: title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });
        else
          result = await apiService.WellServices.getSingleValueLog({
            well_id: this.selectedWell,
            wellbore_name: this.selectedWellbore,
            field: title,
            type: mnemonic_type,
            max: this.max,
            topic: this.topic,
          });
        if (result != null) {
          value = result;
          console.log(result, "result of the apis");
        }
      }
      return value;
    },
    onclickoftripout() {
      let totalfts = 0;

      // Calculate the total length of all components
      if (this.tableData.length > 0) {
        this.tableData.forEach((eachone) => {
          totalfts += eachone.componentLength;
        });
      }

      // Loop to ensure total length is within the bit depth
      while (totalfts > this.bitdepth && this.tableData.length > 0) {
        // Get the last item
        let lastItemIndex = this.tableData.length - 1;
        let lastItem = this.tableData[lastItemIndex];
        // console.log(lastItem.componentLength, "while condition");

        // Calculate the reduction amount needed to bring totalfts within the bit depth
        let reductionAmount = totalfts - this.bitdepth;

        // Reduce the componentLength by the reductionAmount, but not below zero
        let actualReduction = Math.min(
          reductionAmount,
          lastItem.componentLength
        );
        lastItem.componentLength -= actualReduction;
        totalfts -= actualReduction;

        // Check if the componentLength is less than or equal to zero
        if (lastItem.componentLength <= 0) {
          // Remove the last item from the array
          this.tableData.pop();
        } else {
          // Ensure the updated item is reassigned to the array if it still has positive length
          this.tableData[lastItemIndex] = lastItem;

          // if (this.tooltipContent && !this.isCasingTooltip) {
          //   if (
          //     this.tooltipContent.bhaData.componenttype ==
          //     lastItem.bhaData.componenttype
          //   ) {
          //     this.tooltipContent.componentLength = lastItem.componentLength;
          //   }
          // }

          // this.handleCanvasMouseMove()
        }
      }

      if (totalfts <= this.bitdepth) {
        console.log("Total length is now within the bit depth:", totalfts);
      } else {
        console.log(
          "Reduction could not meet bit depth requirement, total length:",
          totalfts
        );
      }

      this.drawComponents("from onclickoftripout");
      this.updateTooltip();
    },
    onclickofreset() {
      const ctx = this.context;
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.zoomedScale = null;
      d3.select(this.$refs.drillingCanvas)
        .transition()
        .duration(750)
        .call(this.zoom.transform, this.initialTransform);
      // this.$refs.drillingCanvas.resetZoom();

      this.tableData = JSON.parse(JSON.stringify(this.originalData));
      this.onclickoftripout();
    },

    zoomed({ transform }) {
      if (!this.isZoomEnable) return false;
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // Update scales with zoom

      const new_yScale = transform.rescaleY(this.yScale);
      this.zoomedScale = new_yScale;

      this.miny = new_yScale.domain()[0];
      this.maxy = new_yScale.domain()[1];
      console.log(this.miny, this.maxy, "miny after zoom");

      this.drawComponents("from zoom");
      this.updateTooltip();
    },

    initializeZoom() {
      const zoom = d3
        .zoom()
        .scaleExtent([1, 500])
        .translateExtent([
          [0, 0],
          [this.$refs.drillingCanvas.width, this.$refs.drillingCanvas.height],
        ])
        .extent([
          [0, 0],
          [this.$refs.drillingCanvas.width, this.$refs.drillingCanvas.height],
        ])
        .on("zoom", this.zoomed);
      // console.log(zoom, "zoom calling");

      d3.select(this.$refs.drillingCanvas).call(zoom);
      this.zoom = zoom;
      this.initialTransform = d3.zoomIdentity;
      // console.log("zoomed initialized");
    },

    drawScale() {
      const canvas = this.canvas;
      const context = this.context;

      // Define margins and dimensions
      const margin = { top: 6, right: 20, bottom: 10, left: 45 };
      const width = canvas.width - margin.left - margin.right;
      const height = canvas.height - margin.top - margin.bottom;

      // Create a D3 scale for depth with normal range
      let scale;
      
      // console.log(this.zoomedScale , this.isZoomEnable,this.miny > 0 ,this.maxy < this.holedepth , 'iszoomed')
      if (this.zoomedScale!= null && this.isZoomEnable) {
        if (this.miny > 0 && parseInt(this.maxy) < parseInt(this.holedepth)) {
          scale = this.zoomedScale;
          this.isZoomSatisfied = true;
        } else if (this.miny <= 0 && parseInt(this.maxy) < parseInt(this.holedepth)) {
          scale = this.zoomedScale;
          this.isZoomSatisfied = true;
        } else if (this.miny > 0 && parseInt(this.maxy) >= parseInt(this.holedepth)) {
          scale = this.zoomedScale;
          this.isZoomSatisfied = true;
        } else {
          console.log("coming to else condition");
          scale = d3
            .scaleLinear()
            .domain([0, this.holedepth]) // Example domain; change based on your data
            .range([0, height - 10]);
          this.yScale = scale;
          this.isZoomSatisfied = false;
          this.zoomedScale = null
        }
        // if(this.miny >= 0 && this.maxy < this.holedepth){
        //   scale = this.zoomedScale;
        // }
        // else if(this.miny > 0 && this.maxy <= this.holedepth){
        //   scale = this.zoomedScale;
        // }
        // console.log(this.zoomedScale, "zoomed scale after the value");

        // console.log("coming to zoomed scale");
      } else {
        console.log("coming to main else condition");
        scale = d3
          .scaleLinear()
          .domain([0, this.holedepth]) // Example domain; change based on your data
          .range([0, height - 10]);
        this.yScale = scale;
        this.isZoomSatisfied = false;
        this.zoomedScale = null
        // console.log("coming to zoomed scale");
        // console.log(this.holedepth, "from scale drawing does it updating");
      }

      console.log(this.isZoomSatisfied && this.zoomedScale!= null,'iszoomscalewoking',this.maxy,'maxy',this.miny,'miny',this.holedepth,'holedepth')

      const tickValues = scale.ticks(10);

      // Clear previous drawings
      context.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the y-axis on the canvas
      context.save();
      context.lineWidth = 5;
      context.beginPath();
      context.moveTo(margin.left, margin.top);
      context.lineTo(margin.left, height + margin.top);
      context.strokeStyle = this.axisColor;
      context.stroke();
      context.restore();

      // Draw the ticks and labels
      context.font = "12px sans-serif";
      context.textAlign = "right";
      context.textBaseline = "middle";
      context.fillStyle = this.axisColor;

      tickValues.forEach((tick) => {
        const y = scale(Math.round(tick, 1)) + margin.top;

        // Draw the circle
        context.save();
        context.beginPath();
        context.arc(margin.left, y, 5, 0, 2 * Math.PI); // Draw a circle with radius 3 on the line
        context.fillStyle = "#488aa3"; // Set the color of the circle
        context.fill(); // Fill the circle with the specified color
        context.restore();

        // Draw the tick label
        console.log("var(--textColor)",'from text colour')
        context.fillStyle = this.axisColor; // Set the text color to black
        context.fillText(tick, margin.left - 10, y);
      });
    },

    // Example of a zoom event handler that updates the zoomed scale
    handleZoom(event) {
      const zoomFactor = event.zoomFactor; // This should be determined based on your zoom logic
      this.zoomedScale = d3
        .scaleLinear()
        .domain([0, this.holedepth / zoomFactor])
        .range([0, this.canvas.height - 10]);
      // Redraw the scale with the updated zoomed scale
      this.drawScale();
    },
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = reject;
      });
    },
    async loadImages() {
      const loadPromises = this.drillBitImages.map((src) =>
        this.loadImage(src)
      );
      this.drillBitImgs = await Promise.all(loadPromises);
    },
    drawSubpipe(ctx, x, y, width, height, component) {
      const gradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(80, 0, 0)"); // Dark maroon
      gradient.addColorStop(0.2, "rgb(100, 0, 0)"); // Darker maroon
      gradient.addColorStop(0.4, "rgb(120, 0, 0)"); // Darker maroon
      gradient.addColorStop(0.5, "rgb(128, 0, 0)"); // Maroon
      gradient.addColorStop(0.6, "rgb(139, 0, 0)"); // Maroon
      gradient.addColorStop(0.7, "rgb(150, 0, 0)"); // Maroon
      gradient.addColorStop(0.8, "rgb(165, 42, 42)"); // Maroon
      gradient.addColorStop(0.85, "rgb(178, 34, 34)"); // Lighter maroon
      gradient.addColorStop(0.9, "rgb(192, 39, 39)"); // Light maroon
      gradient.addColorStop(1, "rgb(205, 41, 41)"); // L Dark

      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.fillRect(x + width - 10, y, 10, height);

      // ctx.beginPath();
      // ctx.moveTo(x + width, y + height / 2);
      // ctx.lineTo(x + width + 50, y + height / 2); // Adjust the length of the line as needed
      // ctx.strokeStyle = "black";
      // ctx.stroke();

      // // Add the name of the component
      // ctx.fillStyle = "black";
      // ctx.font = "12px Arial";
      // ctx.fillText(
      //   component.bhaData.componenttype,
      //   x + width + 55,
      //   y + height / 2 + 4
      // );
    },
    drawJar(ctx, x, y, width, height) {
      const topHeight = height / 3;
      const bottomHeight = height / 3;
      const centerHeight = height / 3;

      // Create gradient for top section
      // const topGradient = ctx.createLinearGradient(x, y, x, y + topHeight);
      const topGradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      topGradient.addColorStop(0, "rgb(225, 45, 12)"); // Dark orange-red
      topGradient.addColorStop(0.2, "rgb(245, 85, 35)"); // Lighter orange-red
      topGradient.addColorStop(0.4, "rgb(255, 120, 60)"); // Lighter orange
      topGradient.addColorStop(0.6, "rgb(255, 160, 90)"); // Even lighter orange
      topGradient.addColorStop(0.8, "rgb(255, 190, 120)"); // Light orange
      topGradient.addColorStop(1, "rgb(225, 100, 70)"); // Middle-light orange

      // Use the gradient to fill the top section
      ctx.fillStyle = topGradient;
      ctx.fillRect(x, y, width, topHeight);

      // Create gradient for bottom section
      const bottomGradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      bottomGradient.addColorStop(0, "rgb(225, 45, 12)"); // Dark orange-red
      bottomGradient.addColorStop(0.2, "rgb(245, 85, 35)"); // Lighter orange-red
      bottomGradient.addColorStop(0.4, "rgb(255, 120, 60)"); // Lighter orange
      bottomGradient.addColorStop(0.6, "rgb(255, 160, 90)"); // Even lighter orange
      bottomGradient.addColorStop(0.8, "rgb(255, 190, 120)"); // Light orange
      bottomGradient.addColorStop(1, "rgb(225, 100, 70)"); // Middle-light orange

      // Use the gradient to fill the bottom section
      ctx.fillStyle = bottomGradient;
      ctx.fillRect(x, y + height - bottomHeight, width, bottomHeight);

      // Create white color for center section
      const centerGradient = ctx.createLinearGradient(
        x + width / 4,
        y + topHeight,
        x + (3 * width) / 4,
        y + topHeight + centerHeight
      );
      centerGradient.addColorStop(0, "#FFFFFF"); // White
      centerGradient.addColorStop(1, "#FFFFFF"); // White

      // Use the gradient to fill the center section
      ctx.fillStyle = centerGradient;
      ctx.fillRect(x, y + topHeight, width, centerHeight);

      // Fill the inner small pipe with the gradient
      const increasedMargin = width / 10; // Increase the margin
      const smallPipeMargin = width / 12 + increasedMargin; // Adjust the margin as needed
      const smallPipeWidth = width - 2 * smallPipeMargin; // Same width as the normal pipe minus the margins
      const smallPipeX = x + width / 2 - smallPipeWidth / 2; // Center the small pipe horizontally
      const smallPipeHeight = centerHeight;
      const smallPipeY = y + topHeight + centerHeight / 2 - smallPipeHeight / 2;

      const smallPipeGradient = ctx.createLinearGradient(
        smallPipeX, // Start from the left side of the small pipe
        smallPipeY, // Top of the small pipe
        smallPipeX + smallPipeWidth, // End at the right side of the small pipe
        smallPipeY // Bottom of the small pipe
      );
      // const gradient = ctx.createLinearGradient(x, y, x + width, y);
      smallPipeGradient.addColorStop(0, "rgb(58,64,65)"); // Dark
      smallPipeGradient.addColorStop(0.1, "rgb(67,76,76)"); // Darker
      smallPipeGradient.addColorStop(0.2, "rgb(77,84,83)"); // Darker
      smallPipeGradient.addColorStop(0.3, "rgb(134,140,139)"); // Darker
      smallPipeGradient.addColorStop(0.5, "rgb(205,209,214)"); // Light
      smallPipeGradient.addColorStop(0.5, "rgb(205,209,214)");
      smallPipeGradient.addColorStop(0.7, "rgb(205,209,214)"); // Middle-Light
      smallPipeGradient.addColorStop(0.8, "rgb(99,103,109)"); // Middle-Light
      smallPipeGradient.addColorStop(0.9, "rgb(67,76,76)"); // Darker
      smallPipeGradient.addColorStop(1, "rgb(58,64,65)");

      ctx.fillStyle = smallPipeGradient;
      ctx.fillRect(smallPipeX, smallPipeY, smallPipeWidth, smallPipeHeight);
      // Optionally, stroke the outline of the pipe
      // ctx.strokeRect(x, y, width, height);
    },
    drawFloatSub(ctx, x, y, width, height, component) {
      const gradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(0, 0, 102)"); // Dark navy blue
      gradient.addColorStop(0.1, "rgb(0, 26, 102)"); // Darker navy blue
      gradient.addColorStop(0.2, "rgb(0, 51, 102)"); // Darker navy blue
      gradient.addColorStop(0.3, "rgb(0, 77, 153)"); // Darker navy blue
      gradient.addColorStop(0.5, "rgb(0, 102, 204)"); // Light navy blue
      gradient.addColorStop(0.5, "rgb(0, 102, 204)"); // Light navy blue
      gradient.addColorStop(0.7, "rgb(26, 128, 230)"); // Middle-Light navy blue
      gradient.addColorStop(0.8, "rgb(77, 153, 255)"); // Middle-Light navy blue
      gradient.addColorStop(0.9, "rgb(102, 178, 255)"); // Dark navy blue
      gradient.addColorStop(1, "rgb(153, 204, 255)"); // Light

      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.strokeStyle = "black";
      ctx.fillRect(x + width - 10, y, 10, height);
    },
    drawMuleShoe(ctx, x, y, width, height) {
      const gradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(225, 45, 12)"); // Dark orange-red
      gradient.addColorStop(0.2, "rgb(245, 85, 35)"); // Lighter orange-red
      gradient.addColorStop(0.4, "rgb(255, 120, 60)"); // Lighter orange
      gradient.addColorStop(0.6, "rgb(255, 160, 90)"); // Even lighter orange
      gradient.addColorStop(0.8, "rgb(255, 190, 120)"); // Light orange
      gradient.addColorStop(1, "rgb(225, 100, 70)"); // Middle-light orange
      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.fillRect(x + width - 10, y, 10, height);
      ctx.stroke();
    },
    drawReamer(ctx, x, y, width, height) {
      ctx.fillStyle = "black";
      ctx.fillRect(x, y, width, height);

      // Draw horizontal strips with a slight bend
      ctx.strokeStyle = "silver";
      ctx.lineWidth = 5;
      const numStrips = 5;
      const stripHeight = height / numStrips;

      for (let i = 1; i < numStrips; i++) {
        const stripY = y + i * stripHeight;
        const bendAmount =
          Math.sin((i / (numStrips - 1)) * Math.PI) * (width / 10); // Adjust the factor for the bend amount
        ctx.beginPath();
        ctx.moveTo(x + bendAmount, stripY);
        ctx.lineTo(x + width - bendAmount, stripY);
        ctx.stroke();
      }

      // Draw black dots on the strips (excluding the extreme ends)
      const dotRadius = 2;
      const dotSpacing = 20;

      ctx.fillStyle = "black";
      for (let i = 1; i < numStrips; i++) {
        const stripY = y + i * stripHeight;
        const bendAmount =
          Math.sin((i / (numStrips - 1)) * Math.PI) * (width / 10); // Adjust the factor for the bend amount
        for (
          let j = dotSpacing + bendAmount;
          j < width - dotSpacing - bendAmount;
          j += dotSpacing
        ) {
          // Exclude extreme ends
          ctx.beginPath();
          ctx.arc(x + j, stripY, dotRadius, 0, 2 * Math.PI);
          ctx.fill();
        }
      }
    },
    drawlwd(ctx, x, y, width, height) {
      // Create the gray gradient
      const gradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(0, 51, 102)"); // Dark blue
      gradient.addColorStop(0.1, "rgb(26, 78, 130)"); // Darker blue
      gradient.addColorStop(0.2, "rgb(51, 102, 153)"); // Darker blue
      gradient.addColorStop(0.3, "rgb(77, 128, 179)"); // Darker blue
      gradient.addColorStop(0.5, "rgb(128, 166, 204)"); // Light blue
      gradient.addColorStop(0.5, "rgb(128, 166, 204)"); // Light blue
      gradient.addColorStop(0.7, "rgb(153, 179, 204)"); // Middle-Light blue
      gradient.addColorStop(0.8, "rgb(153, 179, 204)"); // Middle-Light blue
      gradient.addColorStop(0.9, "rgb(102, 128, 153)"); // Darker blue
      gradient.addColorStop(1, "rgb(0, 51, 102)");

      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.fillRect(x + width - 10, y, 10, height);
      // ctx.fillStyle = "rgb(94, 97, 95)";
      ctx.fillRect(x, y, width, height);

      // Draw horizontal strips with a slight bend
      ctx.strokeStyle = "silver";
      ctx.lineWidth = 5;
      const numStrips = 5;
      const stripHeight = height / numStrips;

      for (let i = 1; i < numStrips; i++) {
        const stripY = y + i * stripHeight;
        const bendAmount = Math.sin((i / numStrips) * Math.PI) * width * 0.1; // Adjust the 0.1 factor for the bend amount
        ctx.beginPath();
        ctx.moveTo(x + bendAmount, stripY);
        ctx.lineTo(x + width - bendAmount, stripY);
        ctx.stroke();
      }

      // Draw black dots on the strips (excluding the extreme ends)
      const dotRadius = 2;
      const dotSpacing = 20;

      ctx.fillStyle = "black";
      for (let i = 1; i < numStrips; i++) {
        const stripY = y + i * stripHeight;
        for (let j = dotSpacing; j < width - dotSpacing; j += dotSpacing) {
          // Exclude extreme ends
          const bendAmount = Math.sin((i / numStrips) * Math.PI) * width * 0.1;
          ctx.beginPath();
          ctx.arc(x + j + bendAmount, stripY, dotRadius, 0, 2 * Math.PI);
          ctx.fill();
        }
      }
    },
    drawMudMotor(ctx, x, y, width, height, component) {
      // Create the orange gradient
      const gradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(225, 45, 12)"); // Dark orange-red
      gradient.addColorStop(0.2, "rgb(245, 85, 35)"); // Lighter orange-red
      gradient.addColorStop(0.4, "rgb(255, 120, 60)"); // Lighter orange
      gradient.addColorStop(0.6, "rgb(255, 160, 90)"); // Even lighter orange
      gradient.addColorStop(0.8, "rgb(255, 190, 120)"); // Light orange
      gradient.addColorStop(1, "rgb(225, 100, 70)"); // Middle-light orange

      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.fillRect(x + width - 10, y, 10, height);

      // Draw the vertical intertwined snake-like patterns inside the pipe
      const centerX = x + width / 2;
      const numWaves = 20; // Number of waves in the snake pattern
      const waveWidth = width / 4; // Width of each wave
      const waveHeight = height / numWaves; // Height of each wave

      ctx.strokeStyle = "silver"; // Color of the snake pattern
      ctx.lineWidth = 2; // Thickness of the snake pattern line

      ctx.beginPath();
      ctx.moveTo(centerX - waveWidth / 2, y); // Start the first wire pattern

      for (let i = 0; i < numWaves; i++) {
        const controlPointX1 =
          centerX - (i % 2 === 0 ? waveWidth / 2 : -waveWidth / 2);
        const controlPointY1 = y + i * waveHeight + waveHeight / 2;
        const endX = centerX - waveWidth / 2;
        const endY = y + (i + 1) * waveHeight;

        ctx.quadraticCurveTo(controlPointX1, controlPointY1, endX, endY);
      }

      ctx.moveTo(centerX + waveWidth / 2, y); // Start the second wire pattern

      for (let i = 0; i < numWaves; i++) {
        const controlPointX1 =
          centerX + (i % 2 === 0 ? waveWidth / 2 : -waveWidth / 2);
        const controlPointY1 = y + i * waveHeight + waveHeight / 2;
        const endX = centerX + waveWidth / 2;
        const endY = y + (i + 1) * waveHeight;

        ctx.quadraticCurveTo(controlPointX1, controlPointY1, endX, endY);
      }

      ctx.stroke();

      // ctx.beginPath();
      // ctx.moveTo(x + width, y + height / 2);
      // ctx.lineTo(x + width + 50, y + height / 2); // Adjust the length of the line as needed
      // ctx.strokeStyle = "black";
      // ctx.stroke();

      // Add the name of the component
      // ctx.fillStyle = "black";
      // ctx.font = "12px Arial";
      // ctx.fillText(
      //   component.bhaData.componenttype,
      //   x + width + 55,
      //   y + height / 2 + 4
      // );
    },
    drawmwd(ctx, x, y, width, height) {
      const gradient = ctx.createLinearGradient(x, y, x + width, y);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(255, 204, 0)"); // Dark yellow
      gradient.addColorStop(0.1, "rgb(255, 221, 51)"); // Darker yellow
      gradient.addColorStop(0.2, "rgb(255, 238, 102)"); // Darker yellow
      gradient.addColorStop(0.3, "rgb(255, 255, 153)"); // Darker yellow
      gradient.addColorStop(0.5, "rgb(255, 255, 204)"); // Light yellow
      gradient.addColorStop(0.5, "rgb(255, 255, 204)"); // Light yellow
      gradient.addColorStop(0.7, "rgb(255, 255, 204)"); // Middle-Light yellow
      gradient.addColorStop(0.8, "rgb(255, 238, 102)"); // Middle-Light yellow
      gradient.addColorStop(0.9, "rgb(255, 204, 0)"); // Dark yellow
      gradient.addColorStop(1, "rgb(255, 153, 0)");

      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.strokeStyle = "black";
      ctx.fillRect(x + width - 10, y, 10, height);
    },
    drawDrillCollar(ctx, x, y, width, height, component) {
      const gradient = ctx.createLinearGradient(x, y, x + width, y);
      ctx.setLineDash([1, 1]);
      ctx.strokeStyle = "white";
      ctx.strokeRect(x, y, width, height);
      ctx.setLineDash([]);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(58,64,65)"); // Dark
      gradient.addColorStop(0.1, "rgb(67,76,76)"); // Darker
      gradient.addColorStop(0.2, "rgb(77,84,83)"); // Darker
      gradient.addColorStop(0.3, "rgb(134,140,139)"); // Darker
      gradient.addColorStop(0.5, "rgb(205,209,214)"); // Light
      gradient.addColorStop(0.7, "rgb(205,209,214)"); // Middle-Light
      gradient.addColorStop(0.8, "rgb(99,103,109)"); // Middle-Light
      gradient.addColorStop(0.9, "rgb(67,76,76)"); // Darker
      gradient.addColorStop(1, "rgb(58,64,65)"); // Dark

      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.fillRect(x + width - 10, y, 10, height);

      // Draw a black line from the right edge of the rectangle
      // ctx.beginPath();
      // ctx.moveTo(x + width, y + height / 2);
      // ctx.lineTo(x + width + 50, y + height / 2); // Adjust the length of the line as needed
      // ctx.strokeStyle = "black";
      // ctx.stroke();

      // // Add the name of the component
      // ctx.fillStyle = "black";
      // ctx.font = "12px Arial";
      // ctx.fillText(
      //   component.bhaData.componenttype,
      //   x + width + 105,
      //   y + height / 2 + 4
      // ); // Adjust position as needed
    },
    drawHWDP(ctx, x, y, width, height, component) {
      const gradient = ctx.createLinearGradient(x, y, x + width, y);
      ctx.setLineDash([1, 1]);
      ctx.strokeStyle = "white";
      ctx.strokeRect(x, y, width, height);
      ctx.setLineDash([]);

      // Add color stops for the gradient
      gradient.addColorStop(0, "rgb(58,64,65)"); // Dark
      gradient.addColorStop(0.1, "rgb(67,76,76)"); // Darker
      gradient.addColorStop(0.2, "rgb(77,84,83)"); // Darker
      gradient.addColorStop(0.3, "rgb(134,140,139)"); // Darker
      // gradient.addColorStop(0.4, "rgb(134,140,139)"); // Middle-Light
      gradient.addColorStop(0.5, "rgb(205,209,214)"); // Light
      gradient.addColorStop(0.5, "rgb(205,209,214)");
      // gradient.addColorStop(0.6, "rgb(143,149,157)"); // Middle-Light
      gradient.addColorStop(0.7, "rgb(205,209,214)"); // Middle-Light
      gradient.addColorStop(0.8, "rgb(99,103,109)"); // Middle-Light
      gradient.addColorStop(0.9, "rgb(67,76,76)"); // Darker
      gradient.addColorStop(1, "rgb(58,64,65)"); // Dark

      // Use the gradient to fill the rectangle
      ctx.fillStyle = gradient;
      ctx.fillRect(x, y, width, height);

      // Optionally, add a shadow effect to the left and right sides
      const shadowColor = "rgba(0, 0, 0, 0.2)";

      // Left shadow
      const leftShadowGradient = ctx.createLinearGradient(x, y, x + 10, y);
      leftShadowGradient.addColorStop(0, shadowColor);
      leftShadowGradient.addColorStop(1, "transparent");
      ctx.fillStyle = leftShadowGradient;
      ctx.fillRect(x, y, 10, height);

      // Right shadow
      const rightShadowGradient = ctx.createLinearGradient(
        x + width - 10,
        y,
        x + width,
        y
      );
      rightShadowGradient.addColorStop(0, "transparent");
      rightShadowGradient.addColorStop(1, shadowColor);
      ctx.fillStyle = rightShadowGradient;
      ctx.fillRect(x + width - 10, y, 10, height);

      // ctx.beginPath();
      // ctx.moveTo(x + width, y + height / 2);
      // ctx.lineTo(x + width + 50, y + height / 2); // Adjust the length of the line as needed
      // ctx.strokeStyle = "black";
      // ctx.stroke();

      // // Add the name of the component
      // ctx.fillStyle = "black";
      // ctx.font = "12px Arial";
      // ctx.fillText(
      //   component.bhaData.componenttype,
      //   x + width + 55,
      //   y + height / 2 + 4
      // );
    },
    handleCanvasMouseLeave() {
      this.hoveredRowData = null;
      this.tooltipContent = null;
      this.showTooltip = false;
    },

    // handleCanvasMouseMove(event) {
    //   const canvas = this.canvas;
    //   if (canvas != null) {
    //     const rect = canvas.getBoundingClientRect();
    //     const x = event.clientX - rect.left;
    //     const y = event.clientY - rect.top;

    //     // console.log(x, y, "mouse movement");
    //     let hoveredComponent = null;
    //     //   console.log(x, y, "mouse value");

    //     for (let i = 0; i < this.tableData.length; i++) {
    //       const component = this.tableData.toReversed()[i];

    //       const {
    //         x: startX = 0,
    //         y: startY = 0,
    //         width: componentWidth = 0,
    //         height: componentHeight = 0,
    //         name: componentname = "",
    //         componentDepth: componentDepth = 0,
    //       } = component.canvasPosition || {};

    //       // console.log(startX, "canvas position");
    //       // console.log(startX, "canvas position");

    //       if (
    //         x >= startX &&
    //         x <= startX + componentWidth &&
    //         y >= startY &&
    //         y <= startY + componentHeight
    //       ) {
    //         hoveredComponent = component;
    //         this.isCasingTooltip = false;
    //         console.log(
    //           startY,
    //           startY + componentHeight,
    //           "from hovered component",
    //           componentname
    //         );
    //         break;
    //       }
    //     }
    //     if (this.wellGeometryList) {
    //       for (let i = 0; i < this.wellGeometryList.length; i++) {
    //         const component = this.wellGeometryList[i];
    //         const {
    //           x: startX = 0,
    //           anotherx: otherx = 0,
    //           y: startY = 0,
    //           width: componentWidth = 0,
    //           height: componentHeight = 0,
    //         } = component.canvasPosition || {};

    //         //   console.log(component.canvasPosition, "canvas position");

    //         if (
    //           x >= startX - 10 &&
    //           x <= startX + componentHeight &&
    //           y >= startY &&
    //           y <= startY + componentHeight
    //         ) {
    //           hoveredComponent = component;
    //           this.isCasingTooltip = true;
    //           console.log(
    //             "from hovered component for casing ---",
    //             hoveredComponent
    //           );
    //           break;
    //         } else if (
    //           x >= otherx &&
    //           x <= otherx + componentHeight &&
    //           y >= startY &&
    //           y <= startY + componentHeight
    //         ) {
    //           hoveredComponent = component;
    //           this.isCasingTooltip = true;
    //           console.log(
    //             "from hovered component for casing ---",
    //             hoveredComponent
    //           );
    //           break;
    //         }
    //       }
    //     }

    //     if (hoveredComponent) {
    //       // console.log(hoveredComponent, "hovered component");
    //       this.tooltipContent = hoveredComponent;
    //       this.tooltipTop = event.clientY - 150; // Add an offset if needed
    //       this.tooltipLeft = event.clientX + 20;
    //       this.showTooltip = true;
    //     } else {
    //       this.showTooltip = false;
    //       this.tooltipContent = null;
    //       this.isCasingTooltip = false;
    //     }
    //   }
    // },
    handleMouseMove(event) {
      const [mouseX, mouseY] = d3.pointer(event, this.$refs.drillingCanvas);
      this.lastMouseX = mouseX;
      this.lastMouseY = mouseY;
      this.updateTooltip(event);
    },
    updateTooltip(event) {
      const canvas = this.$refs.drillingCanvas;
      let mouseX, mouseY;

      if (canvas) {
        if (event) {
          [mouseX, mouseY] = d3.pointer(event, canvas);
        } else {
          mouseX = this.lastMouseX;
          mouseY = this.lastMouseY;
        }

        if (mouseX != null && mouseY != null) {
          const component = this.getComponentAt(mouseX, mouseY);

          if (component) {
            this.tooltipContent = component;
            this.tooltipTop = event ? event.clientY - 150 : this.tooltipTop; // Add an offset if needed
            this.tooltipLeft = event ? event.clientX + 20 : this.tooltipLeft;
            this.showTooltip = true;
          } else {
            this.showTooltip = false;
            this.tooltipContent = null;
          }
        }
      }
    },
    getComponentAt(x, y) {
      for (let i = 0; i < this.tableData.length; i++) {
        const component = this.tableData[this.tableData.length - 1 - i];
        const {
          x: startX = 0,
          y: startY = 0,
          width: componentWidth = 0,
          height: componentHeight = 0,
        } = component.canvasPosition || {};

        if (
          x >= startX &&
          x <= startX + componentWidth &&
          y >= startY &&
          y <= startY + componentHeight
        ) {
          this.isCasingTooltip = false;
          return component;
        }
      }

      if (this.wellGeometryList) {
        for (let i = 0; i < this.wellGeometryList.length; i++) {
          const component = this.wellGeometryList[i];
          const {
            x: startX = 0,
            anotherx: otherX = 0,
            y: startY = 0,
            width: componentWidth = 0,
            height: componentHeight = 0,
          } = component.canvasPosition || {};

          if (
            (x >= startX - 10 &&
              x <= startX + componentWidth + 10 &&
              y >= startY &&
              y <= startY + componentHeight) ||
            (x >= otherX - 10 &&
              x <= otherX + componentWidth + 10 &&
              y >= startY &&
              y <= startY + componentHeight)
          ) {
            this.isCasingTooltip = true;
            return component;
          }
        }
      }

      return null;
    },
    drawDrillPipe(ctx, x, y, width, height, component) {
      // Draw the pipe
      ctx.fillStyle = "black";
      ctx.fillRect(x, y, width, height);

      // Draw the border
      ctx.setLineDash([1, 1]);
      ctx.strokeStyle = "white";
      ctx.strokeRect(x, y, width, height);
      ctx.setLineDash([]);

      // Calculate the center of the pipe
      const centerX = x + width / 2;
      const centerY = y + height / 2;

      // Draw a line from the center of the pipe to the right
      const lineLength = 80; // Adjust this length as needed
      ctx.beginPath();
      ctx.moveTo(centerX, centerY);
      ctx.lineTo(centerX + lineLength, centerY);
      ctx.strokeStyle = "black";
      ctx.stroke();

      // Draw the text label at the end of the line
      ctx.fillStyle = "black";
      ctx.font = "14px Arial";
      ctx.textAlign = "left"; // Align text to the left of the text position
      ctx.fillText(
        component.bhaData.componenttype,
        centerX + lineLength + 5,
        centerY + 5
      ); // Adjust position slightly below the line
    },
    drawCasingPipe(ctx, x, y, width, height) {
      ctx.fillStyle = "gray";
      ctx.fillRect(x, y, width, height);
      ctx.strokeStyle = "black";
      ctx.strokeRect(x, y, width, height);
    },
    drawTriangle(ctx, x, y1, width, height, direction) {
      ctx.beginPath();
      const y = y1 - height;

      if (direction === "left") {
        ctx.moveTo(x - 20, y1); // Top of the triangle
        ctx.lineTo(x + width, y - height); // Bottom left of the triangle
        ctx.lineTo(x + width, y + height); // Bottom right of the triangle
      } else if (direction === "right") {
        ctx.moveTo(x + 20, y1); // Top of the triangle
        ctx.lineTo(x - width, y - height); // Bottom left of the triangle
        ctx.lineTo(x - width, y + height); // Bottom right of the triangle
      }
      ctx.closePath();
      ctx.fillStyle = "black";
      ctx.strokeStyle = "black";
      ctx.fill();
      ctx.stroke();
    },

    drawComponents(val) {
      //   console.log(val, "draw components");
      const canvas = this.canvas;
      const ctx = this.context;

      if (ctx) ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.save();
      ctx.fillStyle = "#1e293b";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.restore();

      this.drawScale(this.holedepth);
      if (this.backgroundImage) {
        ctx.drawImage(this.backgroundImage, 0, 0, canvas.width, canvas.height);
      }
      cancelAnimationFrame(this.animationFrameId);

      // Create a D3 linear scale for the height
      let heightScale =
      this.zoomedScale!= null && this.isZoomEnable && this.isZoomSatisfied
          ? this.zoomedScale
          : this.yScale;

      // total width of the pipe

      //       ctx.save();
      //   ctx.fillStyle = "black";
      //   ctx.fillRect((canvas.width-60)/2, heightScale(0), 60, heightScale(this.dmea));
      //   ctx.restore();

      let currentY = heightScale(0);
      let list = [];
      let totalfts = 0;
      this.tableData.forEach((eachone) => {
        totalfts += eachone.componentLength;
        if (eachone.odia != "") {
          if (
            eachone.bhaData.componenttype.toLowerCase().includes("Bit") ||
            eachone.bhaData.componenttype.includes("Stabilizer") ||
            eachone.bhaData.componenttype.includes("Reamer")
          ) {
            list.push(parseFloat(eachone.odia * 9));
          } else {
            list.push(parseFloat(eachone.odia * 5));
          }
        }
      });

      const maxOdia = Math.max(...list);
      const maxPercentage = 0.75; // Maximum width as a percentage of total width
      const maxWidth = Math.min(maxOdia * maxPercentage, 60);

      let componentLengthSum = [];
      this.tableData
        .slice()
        .reverse()
        .forEach((component, i) => {
          let originalComponentLength = parseFloat(component.componentLength);

          componentLengthSum.push(
            (componentLengthSum[i - 1] || 0) + originalComponentLength
          );
          let depthFromTop = componentLengthSum[i];
          // console.log(componentLengthSum[i],component.bhaData.componenttype,'how many feets')

          if (i == 0) currentY = heightScale(0);
          else currentY = heightScale(componentLengthSum[i - 1]);
          let componentHeight = Math.abs(
            currentY - heightScale(componentLengthSum[i])
          );

          const componentWidth =
            component.odia * 5
              ? ((component.odia * 5) / maxOdia) * maxWidth
              : this.pipeWidth;
          const startX = (canvas.width - componentWidth) / 2;
          //   console.log(
          //     (canvas.width - 80) / 2,
          //     "startx value from the canvas",
          //     160 + 80
          //   );

          // Draw the components based on their type
          if (component.bhaData.componenttype.includes("pipe")) {
            this.drawDrillCollar(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
          } else if (component.bhaData.componenttype.includes("Stabilizer")) {
            const stabilizerWidth =
              component.odia * 9
                ? ((component.odia * 9) / maxOdia) * maxWidth
                : this.stabilizerWidth;
            const startX = (canvas.width - stabilizerWidth) / 2;

            ctx.drawImage(
              this.stabilizerImg,
              startX,
              currentY,
              stabilizerWidth,
              componentHeight
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("casingpipe")
          ) {
            this.drawCasingPipe(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("collar")
          ) {
            this.drawDrillCollar(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("mwd")
          ) {
            this.drawmwd(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("motor")
          ) {
            this.drawMudMotor(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("nmdc")
          ) {
            this.drawDrillCollar(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("float sub")
          ) {
            this.drawFloatSub(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
          } else if (component.name === "Jar") {
            this.drawJar(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("hwdp")
          ) {
            this.drawHWDP(
              ctx,
              (canvas.width - componentWidth) / 2,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("sub")
          ) {
            this.drawSubpipe(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
          } else if (
            component.bhaData.componenttype.toLowerCase().includes("bit") &&
            this.holedepth > 0 &&
            this.bitdepth > 0
          ) {
            const bitWidth = componentWidth + 10;
            const startX = (canvas.width - bitWidth) / 2;
            this.leftlinestartx = startX;
            const bitHeight = componentHeight + 25;

            ctx.clearRect(startX, currentY, bitWidth, bitHeight);

            ctx.save();
            ctx.fillStyle = "var(--widgetBg)";
            // ctx.fillStyle = 'black'
            ctx.fillRect(startX - 1, currentY - 1, bitWidth + 1, bitHeight + 1);
            ctx.restore();
            if (this.dbtm >= 1) {
              const draw = () => {
                if (this.timeInterval) {
                  clearTimeout(this.timeInterval);
                }

                ctx.drawImage(
                  this.drillBitImgs[this.currentImageIndex],
                  startX,
                  currentY,
                  bitWidth,
                  bitHeight
                );
                if (this.dmea == this.dbtm && this.rop > 0) {
                  this.currentImageIndex =
                    (this.currentImageIndex + 1) % this.drillBitImgs.length;
                } else {
                  this.currentImageIndex = 1;
                }

                this.timeInterval = setTimeout(() => {
                  this.animationFrameId = requestAnimationFrame(draw);
                }, 100);
              };

              draw();
            }
          } else {
            this.drawDrillCollar(
              ctx,
              startX,
              currentY,
              componentWidth,
              componentHeight,
              component
            );
            // if(component.bhaData.componenttype.toLowerCase().includes('DP')){

            //   console.log(component,componentHeight,'from else condition')
            // }
          }

          if (component) {
            component.componentDepth = depthFromTop;
          }

          if (component.bhaData.componenttype.toLowerCase().includes("bit")) {
            component.canvasPosition = {
              x: startX,
              y: currentY,
              width: componentWidth,
              height: componentHeight + 25,
              name: component.bhaData.componenttype,
            };
          } else {
            component.canvasPosition = {
              x: startX,
              y: currentY,
              width: componentWidth,
              height: componentHeight,
              name: component.bhaData.componenttype,
            };
          }
        });

      let maxvalueForcasing;
      let allmdValues = [];
      for (let i of this.wellGeometryList) {
        allmdValues.push(i.md);
      }
      maxvalueForcasing = Math.max(...allmdValues);

      // Draw the open hole background on either side of the pipe
      // ctx.save();
      // ctx.fillStyle = "sandybrown"; // Color for the open hole (sand/rock)
      // const holeDepth = parseFloat(this.holedepth); // Assuming holedepth is available
      // const casingDepth = parseFloat(maxvalueForcasing);
      // const startY = heightScale(casingDepth);
      // const endY = heightScale(holeDepth);
      // const openHoleHeight = Math.abs(endY - startY);

      // Left side
      // ctx.fillRect(canvas.width / 2-80, startY,50, openHoleHeight);
      // // Right side
      // ctx.fillRect(
      //   canvas.width / 2 +30,
      //   startY,
      //   50,
      //   openHoleHeight
      // );
      ctx.restore();

      // Draw vertical lines and triangles
      //   ctx.save();
      //   ctx.beginPath();
      //   ctx.lineWidth = 5;
      //   ctx.strokeStyle = "#a9a8ac";
      //   ctx.moveTo(canvas.width / 2 - 60, heightScale(0));
      //   ctx.lineTo(
      //     canvas.width / 2 - 60,
      //     heightScale(parseFloat(maxvalueForcasing))
      //   );
      //   ctx.moveTo(canvas.width / 2 + 60, heightScale(0));
      //   ctx.lineTo(
      //     canvas.width / 2 + 60,
      //     heightScale(parseFloat(maxvalueForcasing))
      //   );
      //   ctx.stroke();
      //   ctx.restore();

      // ctx.save();
      // ctx.beginPath();
      // ctx.lineWidth = 2;
      // ctx.strokeStyle = "black";
      // ctx.moveTo(canvas.width / 2 - 31, heightScale(0));
      // ctx.lineTo(
      //   canvas.width / 2 - 31,
      //   heightScale(parseFloat(maxvalueForcasing))
      // );
      // ctx.moveTo(canvas.width / 2 + 31, heightScale(0));
      // ctx.lineTo(
      //   canvas.width / 2 + 31,
      //   heightScale(parseFloat(maxvalueForcasing))
      // );
      // ctx.stroke();
      // ctx.restore();

      ctx.fillStyle = "black";
      const triangleBaseWidth = 1;
      const triangleHeight = 7;
      let spacingFactor = 20;
      let i;

      if (this.wellGeometryList) {
        this.wellGeometryList.forEach((y, index) => {
          if (index > 2) {
            i = 2;
          } else {
            i = index;
          }
          //   console.log(y, "from well geometry list", i);

          //draw casing lines
          ctx.save();
          ctx.beginPath();
          ctx.lineWidth = 5;
          ctx.strokeStyle = "#a9a8ac";
          ctx.moveTo(canvas.width / 2 - 80 + i * spacingFactor, heightScale(0));
          ctx.lineTo(
            canvas.width / 2 - 80 + i * spacingFactor,
            heightScale(y.md)
          );
          ctx.moveTo(canvas.width / 2 + 80 - i * spacingFactor, heightScale(0));
          ctx.lineTo(
            canvas.width / 2 + 80 - i * spacingFactor,
            heightScale(y.md)
          );
          ctx.stroke();
          ctx.restore();

          this.drawTriangle(
            ctx,
            canvas.width / 2 - 80 + i * spacingFactor - 5,
            heightScale(y.md),
            triangleBaseWidth,
            triangleHeight,
            "left"
          );
          y.canvasPosition = {
            x: canvas.width / 2 - 80 + i * spacingFactor - 5,
            anotherx: canvas.width / 2 + 80 - i * spacingFactor + 5,
            y: parseInt(heightScale(y.md) - 15),
            width: triangleBaseWidth,
            height: triangleHeight + 5,
            name: y.sectionType,
          };
          this.drawTriangle(
            ctx,
            canvas.width / 2 + 80 - i * spacingFactor + 5,
            heightScale(y.md),
            triangleBaseWidth,
            triangleHeight,
            "right"
          );
        });
      }

      ctx.restore();
    },
  },

  watch: {
    isZoomEnable(val) {
      if (!val) {
        this.onclickofreset();
      }
    },
    async dmea(latest, prev) {
      this.holedepth = latest;

      console.log(latest, "latest dmea from dashboard to stickdiagram");

      this.tableData = JSON.parse(JSON.stringify(this.originalData));
      this.onclickoftripout();
    },
    async dbtm(latest, prev) {
      this.bitdepth = latest;

      console.log(latest, "latest dbtm from dashboard to stickdiagram");
      // await this.drawComponents();
      this.tableData = JSON.parse(JSON.stringify(this.originalData));
      this.onclickoftripout();
    },
  },

  props: {
    height: Number,
    selectedWell: String,
    selectedWellbore: String,
    selectedCustomer: String,
    max: String,
    selectedLog: String,
    dmea: {
      type: Number,
    },
    dbtm: {
      type: Number,
    },
    rop: Number,
  },
};
</script>

<style scoped>
#app {
  background-color: yellow;
}
.zoom-container {
  display: flex;
}
.tool_toggle_input:checked + ._zoom {
  /* background: #00bcd4; */
  background: white;
}
.tool_toggle_label {
  cursor: pointer;
  text-indent: -9999px;
  width: 24px;
  height: 12px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.tool_toggle_label:after {
  content: "";
  position: absolute;
  /* top: 1px; */
  left: 2px;
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 90px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.tool_toggle_label {
  /* background: #5aba81; */
  background: white;
  border: 1px solid black;
}

.tool_toggle_input:checked + .tool_toggle_label:after {
  /* left: calc(100% - 2px); */
  -webkit-transform: translateX(9px);
  transform: translateX(9px);
}

.tool_toggle_input:checked + ._zoom {
  /* background: #00bcd4; */
  background: rgb(85, 202, 223);
}

.tool_toggle_input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}
body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  margin: 0;
  position: relative;
}

canvas {
  margin-top: 10px;
}

table {
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 8px;
  border: 1px solid rgb(187, 184, 184);
  text-align: center;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  width: 200px;
  margin-top: 10px;
  align-items: center;
}
#componentTable {
  background-color: rgb(73, 72, 72);
  border: none;
  color: white;
}
.actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.action-button {
  background-color: inherit;
  border: none;
  color: white;
  outline: none;
}
.common-buttons {
  background-color: rgb(68, 67, 67);
  color: white;
  border-radius: 8px;
  margin: 5px;
  padding: 5px 10px;
}
.common-input {
  background-color: transparent;
  color: white;
  border: none;
  outline: none;
}
.button {
  border: none;
  border-radius: 8px;
  background-color: var(--activeTextColor);
  color: white;
  padding: 0px 5px;
  /* padding: 5px; */
  font-family: "roboto";
  font-weight: bolder;
  align-self: flex-start;
  outline: none;
  font-weight: bold;
}
.button:hover {
  background-color: white;
  color: black;
  transition: 1s all
}
.tooltip {
  position: absolute;
  background-color: whitesmoke;
  /* border: 1px solid #0ab3e6 !important; */
  padding: 5px;
  border-radius: 4px;
  pointer-events: none;
  z-index: 9999999;

  font-size: 15px;
  color: rgba(40, 40, 47, 255);
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  /* background-color: black; */
}
.details {
  margin-bottom: 1px;
  padding-bottom: 1px;
  font-weight: bold;
}
.zoom-label {
  font-family: sans-serif;
  font-weight: bolder;
  /* color: whitesmoke; */
  font-size: 10px;
  color: var(--textColor);
}
.total-container {
  /* background-color: #1e293b; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}
.canvas {
  /* padding-bottom: 5px; */
}
.tool_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
